/* ----- HEADER CONTENT ----- */

hr {
  width: 50px;
  border: 0px;
  margin-top: 20px;
  margin-bottom: 12px;
  border-top: 2px solid;
  box-sizing: border-box;
  height: 0;
  overflow: visible;
}

.bb-header-image {
  background-image: url(../assets/img/hotels/walaker.jpeg);
  background-size: cover;
  background-position: center;
  height: 585px;
  position: relative;
  z-index: 0;
}

.bb-header--small .bb-header-image {
  height: 350px;
}

@media (max-width: 768px) {
  .bb-header-image {
    height: 225px;
  }
}

.bb-header-image__photocredit {
  position: absolute;
  left: 15px;
  bottom: 20px;
  z-index: 1;
  color: lightgrey;
  font-style: italic;
}

.bb-header-main-text {
  text-align: center;
  color: black;
  padding-top: 60px;
  font-family: futura-light;
  font-size: 40px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

@media (max-width: 768px) {
  .bb-header--small .bb-header-main-text {
    padding-top: 10px;
  }

  .bb-header--small .bb-header-text {
    padding-top: 10px;
  }
}

.bb-header--small .bb-header-main-text {
  padding-top: 45px;
}

.bb-header--small .bb-header-text {
  padding-top: 45px;
}

.bb-header-sub-text {
  text-align: center;
  color: black;
  font-family: futura-light;
  font-size: 20px;
  margin-top: 4px;
}
.bb-welcome-text {
  margin: 12px auto;
  font-size: 40px;
}
.bb-intro-text {
  margin: 40px 12px 12px 12px;
  color: white;
  font-weight: 400;
  text-shadow: 1px 1px 12px #000;
  font-size: 24px;
}

@media (max-width: 768px) {
  .bb-intro-text {
    margin: 12px 12px 12px 12px;
  }
}

@media (max-width: 600px) {
  .bb-welcome-text {
    font-size: 24px;
  }
  .bb-intro-text {
    font-size: 20px;
  }
}
