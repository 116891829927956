.bb-main-content {
  width: 83.333%;
  max-width: 1170px;
  min-width: calc(768px -40px);
  margin: 0px auto;
  min-height: 600px;
  padding-bottom: 60px;
}

.bb-itinerary-loader {
  padding: 10px;
  display: flex;
  width: 80px;
  height: 80px;
  margin: 0 auto;
}
.bb-itinerary-loader:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #f1684b;
  border-color: #f1684b transparent #f1684b transparent;
  -webkit-animation: bb-itinerary-loader 1.2s linear infinite;
  animation: bb-itinerary-loader 1.2s linear infinite;
}
@-webkit-keyframes bb-itinerary-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes bb-itinerary-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
