.bb-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
  text-align: left;
}

.bb-modal-content {
  max-width: 1170px;
  background-color: #fff;
  overflow-y: initial;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin: 12px;
}

.bb-modal-header,
.bb-modal-footer {
  padding: 18px;
}

.bb-modal-title {
  margin: 0;
}

.bb-modal-body {
  padding: 18px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  height: 80vh;
  overflow-y: auto;
}

.bb-modal-q {
  font-size: 1.2em;
  margin: 24px 0 4px;
  font-weight: 600;
}

.bb-modal-a {
  font-style: italic;
  font-size: 1.1em;
}
.bb-modal-list {
  font-size: 1.1em;
  margin: 10px auto;
}

.bb-modal-q:first-child {
  margin-top: 4px;
}

.bb-modal-close-btn {
  display: inline-block;
  padding: 8px 20px;
  background: #f1684b;
  border: 1px solid #f1684b;
  font-weight: bold;
  white-space: nowrap;
  transition: background-color 0.3s ease;
  border-radius: 2px;
  cursor: pointer;
}
