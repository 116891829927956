/* ----- BONUS NIGHT VISUALIZATION ----- */

.bb-bonus-card {
  box-shadow: 4px 4px 18px 4px rgba(128, 128, 128, 0.5);
  text-align: center;
  padding: 20px;
  margin: 64px 0 100px 0;
}
.bb-bonus-text {
  font-size: 26px;
  margin-bottom: 20px;
  margin-top: 10px;
}
.bb-bonus-text2 {
  font-size: 24px;
  margin-bottom: 20px;
}
.bb-bonus-info-modal {
  background: none;
  border: none;
  padding: 0px;
  color: #000;
  text-decoration: underline;
  cursor: pointer;
  font-style: italic;
  font-size: 1.2em;
}

.bb-bonus-code-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bb-bonus-code {
  border-bottom: 4px solid #f1684b;
  font-size: 2em;
  font-weight: 600;
  margin: 0.5em 0;
}

.bb-bonus-code-info {
  margin-bottom: 1.4em;
}

.bb-booking-btn {
  margin: 40px 0 20px 0;
  display: inline-block;
  padding: 16px 60px;
  background: #f1684b;
  border: 1px solid #f1684b;
  font-weight: bold;
  white-space: nowrap;
  transition: background-color 0.3s ease;
  border-radius: 2px;
  cursor: pointer;
}
.bb-booking-btn:hover {
  background: #ed401c;
}
