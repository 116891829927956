.bb-reg-modal {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

/* MODAL DIALOG */

.bb-modal-reg-content {
  position: relative;
  width: auto;
  margin: 8px;
}
@media (min-width: 768px) {
  .bb-modal-reg-content {
    width: 600px;
    margin: 28px auto;
  }
}
@media (min-width: 992px) {
  .bb-modal-reg-content {
    width: 900px;
  }
}

/* MODAL LOGIN*/
.bb-modal-reg-body {
  display: flex;
  text-align: center;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
}

.bb-modal-reg-left {
  display: none;
  width: 50%;
}
@media (min-width: 768px) {
  .bb-modal-reg-left {
    display: flex;
    height: auto;
    max-height: 732px;
  }
}

.bb-modal-reg-image {
  overflow: hidden;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 50;
}

.bb-modal-reg-right {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding: 32px 16px 8px;
}

@media (min-width: 768px) {
  .bb-modal-reg-right {
    width: 50%;
  }
}

.bb-modal-login-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

#bb-login-logo {
  max-width: 80px;
  margin-bottom: 4px;
  border-style: none;
  vertical-align: middle;
}

.bb-login-consent {
  font-size: 14px;
  text-align: center;
  padding: 4px 12px 0 12px;
}

.bb-react-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4px;
}

.bb-form-input {
  width: 200px;
  height: 32px;
  margin-top: 12px;
  margin-bottom: 4px;
  background: none;
  border: none;
  border-bottom: 1px solid black;
}

.bb-form-error {
  margin: 0;
  color: red;
  padding: 0;
  font-size: 12px;
}

.bb-form-submit-btn {
  display: inline-block;
  padding: 8px 20px;
  background: #f1684b;
  border: 1px solid #f1684b;
  font-weight: bold;
  white-space: nowrap;
  transition: background-color 0.3s ease;
  border-radius: 2px;
  cursor: pointer;
  padding: 8px 16px;
  width: 100%;
  max-width: 200px;
  margin-bottom: 8px;
  margin-top: 20px;
}
