.tileList {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 60px;
  margin-bottom: 20px;
}

.tile {
  flex-direction: row;
  align-items: center;
  height: 12px;
  width: 10%;
  background-color: #cecece;
}
.coloredTile {
  height: 12px;
  width: 10%;
  background-color: #f1684b;
}
@media (min-width: 768px) {
  .coloredTile {
    height: 16px;
    width: 10%;
    background-color: #f1684b;
  }
  .tile {
    height: 16px;
    width: 10%;
  }
}
