.bb-login-form-group {
  font-size: 18px;
  margin-bottom: 16px;
  width: 100%;
}

.bb-login-form-group input {
  font-size: 16px;
}

.bb-login-logtext {
  text-align: center;
  text-transform: uppercase;
}

.bb-login-header-text {
  font-family: futura-light;
  font-size: 32px;
  text-align: center;
}

.error-message {
  color: #c6251f;
}

.bb-login-btn {
  display: inline-block;
  padding: 16px 60px;
  background: #f1684b;
  border: 1px solid #f1684b;
  font-weight: bold;
  white-space: nowrap;
  border-radius: 2px;
  margin-bottom: 32px;
  margin-top: 32px;
}

.bb-nomember-reg {
  background: none;
  border: none;
  padding: 0px;
  color: #f1684b;
  font-style: normal;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 16px;
  font-size: 18px;
}

.bb-forgot-password {
  text-align: center;
  text-decoration: underline;
  margin-top: 16px;
}

#email,
#password {
  width: 100%;
  min-width: 100px;
  min-height: 40px;
  line-height: normal;
  border-radius: 2px;
  border: 1px solid #e0e0e0;
  padding-left: 8px;
  margin-top: 8px;
}

.bb-login-content {
  background-color: #fff;
  box-shadow: 0 0 50px 5px rgb(0 0 0 / 9%);
  display: block;
  padding: 20px;
  max-width: 600px;
  z-index: 1;
}
@media (min-width: 768px) {
  .bb-login-content {
    margin-top: -120px;
    padding: 40px;
    width: 30%;
  }
}
