/* ----- PREVIOUS BOOKINGS CARDS ----- */
.bb-previous-title {
  margin-bottom: 40px;
  font-family: futura-light;
  font-size: 32px;
}
.bb-previous-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  -moz-column-gap: 32px;
       column-gap: 32px;
  row-gap: 20px;
  margin-bottom: 100px;
}

@media (max-width: 900px) {
  .bb-previous-grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    row-gap: 4px;
  }
}
.bb-grid-card {
  width: auto;
  height: auto;
  box-shadow: 4px 4px 20px 4px rgba(128, 128, 128, 0.5);
  margin-bottom: 20px;
}
.bb-grid-card:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

#bb-grid-card-img {
  width: 100%;
  height: 250px;
  -o-object-fit: cover;
     object-fit: cover;
}

.bb-grid-hotel-info {
  margin-left: 12px;
  font-family: futura-light;
  font-size: 16px;
}
.bb-grid-hotel-info h3 {
  margin-bottom: 4px;
}

.bb-hotel-place-info {
  font-family: futura-pt;
  font-size: 1.1em;
}
.bb-upcoming-grid-checkin {
  display: flex;
  width: 100%;
  margin: 20px auto;
  margin-left: 12px;
}

.bb-upcoming-grid-confirmation {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  margin-left: 12px;
}
