.bb-campaign-page-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .bb-campaign-page-container {
    flex-direction: row;
    margin: 0px auto;
    width: 83.33%;
    max-width: 1170px;
  }
}

.bb-campaign-title {
  font-size: 18px;
  font-weight: bolder;
  margin: 0px;
  text-transform: uppercase;
}

.bb-welcome-header {
  font-family: futura-light;
  font-size: 32px;
  margin-bottom: 4px;
  margin-top: 36px;
}
.bb-welcome-subheader {
  font-family: futura-light;
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 36px;
}

.bb-campaign-page-main-text {
  font-size: 18px;
}

.bb-campaign-page-content {
  margin: 0 auto;
  padding-top: 48px;
  padding-bottom: 48px;
  width: 90%;
}

@media (min-width: 768px) {
  .bb-campaign-page-content {
    width: 55%;
    margin-right: 10%;
  }
}

.bb-row {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .bb-row {
    flex-direction: row;
  }
}

.bb-app-downloads {
  padding-left: 16px;
  padding-right: 16px;
}
.bb-selling-points-container {
  padding-top: 0px;
  max-width: 1170px;
  width: 83.33%;
  margin: 0 auto 50px;
}
.bb-selling-points-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  justify-content: space-between;
  padding: 0px;
}

@media (min-width: 768px) {
  .bb-selling-points-list {
    flex-direction: row;
  }
}

.bb-selling-point {
  text-align: center;
  padding: 16px;
  max-width: 300px;
  font-size: 16px;
}
.bb-selling-point-title {
  font-weight: bolder;
  font-size: 18px;
}
