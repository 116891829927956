.bb-no-upcoming-stays {
  font-size: 1.2em;
  margin-bottom: 20px;
}
.bb-no-upcoming-sub {
  font-style: italic;
  margin-bottom: 80px;
  font-size: 1.1em;
}

.bb-upcoming-title {
  margin-bottom: 40px;
  font-family: futura-light;
}

.bb-upcoming-card {
  display: flex;
  box-shadow: 4px 4px 20px 4px rgba(128, 128, 128, 0.5);
  margin-bottom: 100px;
}
#bb-card-img {
  width: 80%;
  height: 320px;
  -o-object-fit: cover;
  object-fit: cover;
}
@media (max-width: 900px) {
  .bb-upcoming-card {
    flex-wrap: wrap;
  }
  #bb-card-img {
    min-width: 100%;
  }
}
.bb-card-hotel-info h3 {
  margin-bottom: 4px;
  font-family: futura-light;
  font-size: 24px;
}

.bb-upcoming-card-info {
  width: 100%;
  padding-left: 20px;
}

.bb-upcoming-card-checkin,
.bb-upcoming-card-confirmation,
.bb-card-bonus-info {
  display: flex;
  width: 100%;
  margin: 20px auto;
}

.bb-rooms-booked {
  display: flex;
  flex-direction: column;
  width: 70%;
  justify-content: center;
}
.bb-card-bonus-nights {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

.bb-card-changes {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 20px;
  font-style: italic;
  text-decoration: underline;
}

.bb-card-changes button {
  margin: 20px 0px 0px 0px;
  display: inline-block;
  padding: 16px 20px;
  background: none;
  border: 1px solid grey;
  font-weight: bold;
  white-space: nowrap;
  transition: background-color 0.3s ease;
  border-radius: 2px;
  cursor: pointer;
}

.bb-checkin-details,
.bb-checkout-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

.bb-stays-details {
  display: flex;
  flex-direction: column;
  width: 70%;
  justify-content: center;
}

.bb-confirmation-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}
